<template>
  <loading-dialog
    :isButtonDisabled="!isComplete"
    @complete="onComplete"
    v-model="showDialog"
    :loadingValue="loadingValue"
  >
    <template slot="title">{{ cardTitle }}</template>
    <template slot="subtitle">{{ saveText || cardText }}</template>
    <template slot="button"
      ><span v-if="nextRoute">{{ buttonText }}</span>
      <v-progress-circular indeterminate v-if="!nextRoute"></v-progress-circular
    ></template>
  </loading-dialog>
</template>

<script>
import LoadingDialog from '../components/LoadingDialog.vue';
import { onLogout } from '../vue-apollo';

export default {
  name: 'RegisterComplete',
  data() {
    return {
      nextRoute: null,
      showDialog: true,
      thankyouDialog: true,
      /* tenant customizable options */
      buttonText: this.$t('registration.registerComplete.buttonText'),
      clickToContinue: true,
      cardText: this.$t('registration.registerComplete.cardText'),
      cardTitle: this.$t('registration.registerComplete.cardTitle'),
      init: null,
      /* should the store be reset after this component completes */
      isEndOfRegistration: true,
      checkForExistingUser: false,
      sendNotification: true,
    };
  },
  components: { LoadingDialog },
  computed: {
    isComplete() {
      return this.nextRoute;
    },
    saveText() {
      return this.$store.state.registration.saveText;
    },
    loadingValue() {
      return this.$store.state.loadingValue;
    },
  },
  methods: {
    async completeRegistration() {
      const { checkForExistingUser } = this;
      const nextRoute = await this.$store.dispatch('completeRegistration', {
        memberInfo: { email: this.$store.state.registration.patientData.email, password: null },
        currentRoute: this.$route.name,
        isEndOfRegistration: this.isEndOfRegistration,
        checkForExistingUser,
        sendNotification: this.sendNotification,
        setToken: !this.isEmbedded,
      });

      if (this.isEndOfRegistration && !this.isEmbedded) {
        this.$store.commit('resetRegistration');
        this.$store.commit('restoreSession');
        await onLogout();
      }
      if (nextRoute) {
        this.nextRoute = nextRoute;
      }
      if (this.isPageflowV2) {
        this.nextRoute = true;
      }
      if (!this.clickToContinue) {
        this.onComplete();
      }

      return null;
    },
    getInitialState() {
      const config = this.$store.getters.getConfig(this.$options.name);
      if (!config) return;
      Object.keys(config).forEach((option) => {
        this[option] = config[option];
      });
    },
    onComplete() {
      /*
        TODO: eliminate isPageFlowV2 check once we have
        fully migrated over to pageflow 2.0 system
      */
      this.$store.commit('set', { loadingValue: 0 });
      if (this.isPageflowV2) {
        this.$emit('next');
      } else if (this.nextRoute) {
        this.$router.push({ name: this.nextRoute });
      }
    },
    setAttributes() {
      const { att } = this.$route.query;
      if (!att) return;
      const attributes = att.split('-');

      const currentAttributes = this.$store.state.registration.memberAttributes;
      const memberAttributes = [
        ...(Array.isArray(currentAttributes) ? currentAttributes : []),
        ...attributes,
      ];

      this.$store.commit('setRegistration', {
        memberAttributes,
      });
    },
  },
  async mounted() {
    if (!this.isPageflowV2) {
      this.getInitialState();

      this.$store.watch(
        (state) => state.registration.errors.registerError,
        (value) => {
          if (value) {
            this.$router.push({ name: this.$store.getters.previousFlowRoute(this.$route.name) });
          }
        }
      );
    }
    this.setAttributes();
    this.$store.commit('setRegistration', { errors: { registerError: false } });

    this.$nextTick(async () => {
      await this.completeRegistration();
    });
  },
};
</script>
