<template>
  <v-dialog persistent v-model="value" width="500px">
    <div class="dialog-wrapper d-flex justify-center">
      <v-card class="dialog">
        <div class="d-flex justify-center loading-logo-image--wrap">
          <logo-image height="80" :prefix="$route.params.id" />
        </div>
        <v-card-title class="header text-center d-flex justify-center">
          <slot name="title"></slot>
        </v-card-title>
        <v-card-text class="text-center" style="white-space: pre-line">
          <slot name="subtitle"></slot>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <hover-button
            active="true"
            :disabled="isButtonDisabled"
            @click="$emit('complete')"
            v-if="!isButtonDisabled"
          >
            <slot name="button"></slot>
          </hover-button>
          <div style="width: 200px" v-else>
            <v-progress-linear
              striped
              rounded
              height="14"
              stream
              :buffer-value="100"
              :value="loadingValue"
              class="loading-dialog-progress"
              :color="loadingColor"
            />
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<style lang="scss">
.dialog-wrapper {
  width: 100% !important;
}
.header {
  word-break: keep-all;
}
.dialog {
  width: 500px;
}
</style>
<script>
import HoverButton from './HoverButton.vue';
import LogoImage from './LogoImage.vue';
import { themes } from '../constants/themes';

export default {
  name: 'LoadingDialog',
  data() {
    return {};
  },
  components: { HoverButton, LogoImage },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    isButtonDisabled: {
      required: false,
      type: Boolean,
    },
    loadingValue: Number,
  },
  computed: {
    loadingColor() {
      const { theme } = this.$store.state;

      return (themes[theme] && themes[theme].loadingBar) || 'primary';
    },
  },
};
</script>
